// extracted by mini-css-extract-plugin
export var alignLeft = "s_p5 d_fl d_bC d_dr";
export var alignCenter = "s_bL d_fm d_bz d_ds";
export var alignRight = "s_p6 d_fn d_bD d_dt";
export var alignColumnLeft = "s_qp d_fp d_bK d_dr";
export var alignColumnCenter = "s_qq d_fq d_bL d_ds";
export var alignColumnRight = "s_qr d_fr d_bM d_dt";
export var milestonesContainer = "s_qZ d_dS";
export var milestonesContainerFull = "s_q0 d_dQ";
export var milestonesSubtitle = "s_mj d_mj d_cp";
export var milestonesComponentWrapper = "s_mf d_mf d_cr";
export var compContentWrapper = "s_qv d_mg d_bw d_bF d_bJ d_bB d_c4 d_D";
export var milestonesMainHeader = "s_mc d_mc d_s d_cn";
export var milestonesSubHeader = "s_md d_md d_s";
export var milestonesComponentWrapperRow = "s_q1 d_D";
export var milestonesBlockWrapper = "s_q2";
export var milestonesTextBlockWrapper = "s_q3";
export var milestonesComponentHeader = "s_mh d_mh d_s d_cn";
export var milestonesComponentParagraph = "s_mm d_mm d_s";
export var btnWrapper = "s_dZ d_bw d_cg";
export var btnWrapperCards = "s_qx d_bw d_b9";
export var imageWrapper = "s_q4 d_cp d_bw";
export var exceptionWeight = "s_q5 y_r3";
export var exceptionLineHeight = "s_q6 y_r4";