// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qz d_gs d_cp";
export var heroHeaderCenter = "q_gt d_gt d_cp d_ds";
export var heroHeaderRight = "q_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "q_qB d_gp d_cs";
export var heroParagraphCenter = "q_gq d_gq d_cs d_ds";
export var heroParagraphRight = "q_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "q_qC d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "q_qD d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "q_qF d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "q_qG d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "q_qH d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "q_qJ y_qJ";
export var heroExceptionNormal = "q_qK y_qK";
export var heroExceptionLarge = "q_qL y_qL";
export var Title1Small = "q_qM y_qM y_rC y_rD";
export var Title1Normal = "q_qN y_qN y_rC y_rF";
export var Title1Large = "q_qP y_qP y_rC y_rG";
export var BodySmall = "q_qQ y_qQ y_rC y_rW";
export var BodyNormal = "q_qR y_qR y_rC y_rX";
export var BodyLarge = "q_qS y_qS y_rC y_rY";