// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_q7 d_bz";
export var storyRowWrapper = "t_hv d_hv d_bG";
export var storyLeftWrapper = "t_q8 d_bw d_bL";
export var storyWrapperFull = "t_q9 d_cz";
export var storyWrapperFullLeft = "t_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "t_mC d_hw";
export var storyLeftWrapperCenter = "t_rb d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "t_rc d_hC";
export var storyHeader = "t_rd d_hB d_s d_cp";
export var storyHeaderCenter = "t_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "t_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "t_rf d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "t_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "t_q4 d_fc d_W";
export var imageWrapperFull = "t_rg d_s d_D d_bb d_W";